
import { textSearch } from "@/assets/ts/_utils";
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { SortProps, sortFn } from "@/core/helpers/sort";
import { FormularioEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { AuthModule, FamiliasFormulariosModule } from "@/store/modules";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useArrayPagination } from "vue-composable";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "formularios-familias-list",
  components: { "download-excel": JsonExcel, Page },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const module = getModule(FamiliasFormulariosModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;
    const csvLabels = {
      nombreComarca: t("csv.solicitudes.familias.comarca"),
      codigo: t("csv.solicitudes.familias.codigo"),
      createdOnCsv: t("csv.solicitudes.familias.createdOn"),
      dni: t("csv.solicitudes.familias.dni"),
      nombre: t("csv.listados.familias.nombre"),
      apellidos: t("csv.listados.familias.apellidos"),
      telefono: t("csv.listados.familias.telefono"),
      email: t("csv.listados.familias.email"),
      direccion: t("csv.listados.familias.direccion"),
      localidad: t("csv.listados.familias.localidad"),
      provincia: t("csv.listados.familias.provincia"),
      ingresos: t("formularios.familiaDetalle.ingresos.label"),
      familiaPrioritaria: t("csv.listados.familias.familiaPrioritaria"),
      estado: t("csv.solicitudes.familias.estado"),
    };
    const csvFields = [
      ...(!comarcaId ? ["nombreComarca"] : []),
      "codigo",
      "createdOnCsv",
      "dni",
      "nombre",
      "apellidos",
      "telefono",
      "email",
      "direccion",
      "localidad",
      "provincia",
      "ingresos",
      "familiaPrioritaria",
      "estado",
    ];
    const csvName = "familias" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const error = computed(() => module.errors);

    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });
    const cargaListado = ref(false);

    onBeforeMount(async () => {
      cargaListado.value = true;
      try {
        await module.fetchFormularios(comarcaId);
        search.value = "";
        currentPage.value = 1;
      } catch (err) {
        Swal.fire({
          text: t("formularios.familiaListado.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaListado.value = false;
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs(t("menu.familias"), [t("menu.solicitudes")]);
    });
    const handleEdit = (index, row) => {
      router.push(`familias/${row.id}`);
    };

    const familias = computed(() => {
      // NOTA: Necesario para exportar los campos
      return module.familiasFormularios?.map((ele) => {
        let familia;
        if (!ele.datosSolicitud.email) {
          familia = module.familiasFormularios.find((familia) => {
            if (
              familia.dni === ele.dni &&
              familia.datosSolicitud.telefono === ele.datosSolicitud.telefono &&
              typeof familia.datosSolicitud.email !== "undefined"
            ) {
              return true;
            } else if (
              familia.dni === ele.familia?.dni &&
              familia.datosSolicitud.telefono === ele.familia?.telefono
            ) {
              return true;
            }
            return false;
          });
        }

        return {
          ...ele,
          createdOnCsv: new Date(ele.createdOn),
          nombre: ele.datosSolicitud.nombre,
          apellidos: ele.datosSolicitud.apellidos,
          solicitante: ele.datosSolicitud.nombre
            ? [ele.datosSolicitud.nombre, ele.datosSolicitud.apellidos].join(
                " "
              )
            : familia?.datosSolicitud.nombre
            ? [
                familia?.datosSolicitud.nombre,
                familia?.datosSolicitud.apellidos,
              ].join(" ")
            : [ele.familia?.nombre, ele.familia?.apellidos].join(" "),
          telefono: ele.datosSolicitud.telefono,
          email: ele.datosSolicitud.email,
          direccion: ele.datosSolicitud.direccion,
          localidad: ele.datosSolicitud.localidad,
          provincia: ele.datosSolicitud.provincia,
          nombreComarca: ele.comarca?.nombre,
          ingresos: ele.datosSolicitud.ingresos,
          familiaPrioritaria: ele.datosSolicitud.familiaPrioritaria,
          familiaId: familia?.id || ele.familia?.id || "",
          rutaFamilia: ele.familia?.id
            ? "/listados/familias/" + ele.familia?.id
            : familia?.id
            ? "/solicitudes/familias/" + familia?.id
            : "",
        };
      });
    });

    const sortProps = ref<SortProps>({
      prop: "createdOn",
      order: "descending",
      method: null,
    });

    const filteredCollection = computed(() =>
      (familias.value ?? [])
        .slice()
        .sort(sortFn(sortProps))
        .filter((data) => {
          return (
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.solicitante, search.value) ||
            textSearch(data.datosSolicitud?.dni, search.value) ||
            textSearch(data.datosSolicitud?.email, search.value) ||
            textSearch(data.datosSolicitud?.telefono, search.value) ||
            textSearch(data.estado, search.value)
          );
        })
    );

    const { result, currentPage, lastPage } = useArrayPagination(
      filteredCollection,
      { pageSize: 100 }
    );

    return {
      familiasFormularios: filteredCollection,
      cargaListado,
      isAdmin: !comarcaId,
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      FormularioEstado,
      search,
      toLocaleDateString,
      result,
      currentPage,
      lastPage,
      handleCurrentPageChange: (page: number) => (currentPage.value = page),
      sortChange: ({ column, prop, order }) => {
        sortProps.value = { prop, order, method: column.sortMethod };
      },
    };
  },
});
