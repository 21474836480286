import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_8 = ["title"]
const _hoisted_9 = { class: "card-body py-3" }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_download_excel = _resolveComponent("download-excel")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_Page = _resolveComponent("Page")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_Page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("tabla.familias.titulo")), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("tabla.familias.numeroSolicitudes", {
                numero: _ctx.familiasFormularios.length,
              })), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_input, {
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              style: {"width":"300px"},
              placeholder: _ctx.$t('tabla.buscador.placeholder')
            }, null, 8, ["modelValue", "placeholder"]),
            _createVNode(_component_download_excel, {
              data: _ctx.familiasFormularios,
              labels: _ctx.csvLabels,
              fields: _ctx.csvFields,
              name: _ctx.csvName
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_7, [
                  _createElementVNode("i", {
                    class: "fas fa-file-excel fs-3",
                    title: _ctx.$t('tabla.familias.boton.imprimir')
                  }, null, 8, _hoisted_8)
                ])
              ]),
              _: 1
            }, 8, ["data", "labels", "fields", "name"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _withDirectives((_openBlock(), _createBlock(_component_el_table, {
            data: _ctx.result,
            style: {"width":"100%"},
            stripe: "",
            "default-sort": { prop: 'createdOn', order: 'descending' },
            onSortChange: _ctx.sortChange
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.familias.cabecera.fecha'),
                prop: "createdOn",
                formatter: _ctx.toLocaleDateString,
                sortable: "",
                width: "100"
              }, null, 8, ["label", "formatter"]),
              (_ctx.isAdmin)
                ? (_openBlock(), _createBlock(_component_el_table_column, {
                    key: 0,
                    label: _ctx.$t('tabla.familias.cabecera.comarca'),
                    prop: "nombreComarca",
                    sortable: "",
                    "min-width": "150"
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.familias.cabecera.solicitante'),
                prop: "solicitante",
                sortable: "",
                "min-width": "150"
              }, {
                default: _withCtx((scope) => [
                  (scope.row.familiaId)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 0,
                        content: _ctx.$t('listado.familias.tooltip.segundaSolicitud'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, {
                            to: `${scope.row.rutaFamilia}`
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(scope.row.solicitante), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(scope.row.solicitante), 1))
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.familias.cabecera.dni'),
                prop: "datosSolicitud.dni",
                sortable: "",
                width: "120"
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.familias.cabecera.email'),
                prop: "datosSolicitud.email",
                sortable: "",
                "min-width": "150"
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.familias.cabecera.telefono'),
                prop: "datosSolicitud.telefono",
                sortable: "",
                width: "120"
              }, null, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                label: _ctx.$t('tabla.familias.cabecera.estado'),
                prop: "estado",
                sortable: "",
                width: "120",
                fixed: "right"
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_tag, {
                    class: "ml-2",
                    type: 
                  scope.row.estado == _ctx.FormularioEstado.incorporado
                    ? 'success'
                    : scope.row.estado == _ctx.FormularioEstado.noApto ||
                      scope.row.estado == _ctx.FormularioEstado.realizadoPorError
                    ? 'danger'
                    : ''
                
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(`formularios.estado.${scope.row.estado}`)), 1)
                    ]),
                    _: 2
                  }, 1032, ["type"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_el_table_column, {
                width: "80",
                fixed: "right"
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_button, {
                    size: "small",
                    type: "text",
                    onClick: ($event: any) => (_ctx.handleEdit(scope.$index, scope.row))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("tabla.familias.fila.ver")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data", "onSortChange"])), [
            [_directive_loading, _ctx.cargaListado]
          ]),
          _createVNode(_component_el_pagination, {
            class: "my-5 float-end",
            background: "",
            "page-size": 100,
            layout: "prev, pager, next",
            total: _ctx.familiasFormularios.length,
            "hide-on-single-page": true,
            "current-page": _ctx.currentPage,
            onCurrentChange: _ctx.handleCurrentPageChange
          }, null, 8, ["total", "current-page", "onCurrentChange"])
        ])
      ])
    ]),
    _: 1
  }))
}